import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowButton } from "../../Components/Button";
import DoorJames from "./../../Images/DoorJames.svg";
import CGI from "./../../Images/CGI.png";

export default function PageNotFound() {
  const navigate = useNavigate();

  function goHomeHandler() {
    navigate("/event/nF6siAAYmbt8LaQh1Wd9/");
  }

  return (
    <div className="h-full flex flex-col items-center justify-around">
      <PageNotFoundLogo />
      <PageNotFoundContent goHomeHandler={goHomeHandler} />
      <PageNotFoundSmallLogo />
    </div>
  );
}

function PageNotFoundLogo() {
  return <img className="w-48 sm:w-60" src={DoorJames} alt="Door_James_Logo" />;
}

function PageNotFoundContent({ goHomeHandler }) {
  return (
    <div className="flex flex-col items-center text-center w-72 gap-6">
      <p className="text-custom-blue text-5xl font-medium">404</p>
      <div className="gap-2 flex flex-col">
        <p className="font-medium text-2xl">Page Not Found</p>
        <p>
          The page you are looking for might be renamed, removed, or might never
          exist.
        </p>
      </div>
      <PageNotFoundButton goHomeHandler={goHomeHandler} />
    </div>
  );
}

function PageNotFoundButton({ goHomeHandler }) {
  return (
    <div className="w-64">
      <ArrowButton
        type="filled"
        text="Go Home"
        action={() => goHomeHandler()}
      />
    </div>
  );
}

function PageNotFoundSmallLogo() {
  return (
    <div className="flex flex-col gap-16 justify-center items-center">
      <img className="w-20 sm:w-28" src={CGI} alt="CGI_Logo" />
      <HomepagePrivacyPolicy />
    </div>
  );
}

function HomepagePrivacyPolicy() {
  const url = "http://doorjames.com/privacy";
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-custom-darkgray underline text-sm sm:text-base cursor-pointer"
    >
      Privacy Policy
    </a>
  );
}
