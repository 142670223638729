import { format } from "date-fns";

export function sortMeetingsByDay(meetings) {
  const meetingsMap = new Map();

  meetings.forEach((meeting) => {
    let date = meeting.data.time.from.toDate();
    date = format(date, "yyyy-MM-dd");
    const meetingID = meeting.id;
    const meetingData = meeting.data;

    if (meetingsMap.has(date)) {
      const meetingsAtDate = meetingsMap.get(date);
      const newMeeting = { [meetingID]: meetingData };

      const newMeetingsMapObject = {
        meetings: [...meetingsAtDate.meetings, newMeeting],
        date,
      };

      meetingsMap.set(date, newMeetingsMapObject);
    } else {
      const newMeeting = { meetings: [{ [meetingID]: meetingData }], date };
      meetingsMap.set(date, newMeeting);
    }
  });

  return meetingsMap;
}

export function sortMeetingsByTime(meetingsData) {
  if (!meetingsData?.meetings) return;

  meetingsData.meetings.sort((a, b) => {
    const meetingIDA = Object.keys(a)[0];
    const timeA = a[meetingIDA].time.from.toDate();

    const meetingIDB = Object.keys(b)[0];
    const timeB = b[meetingIDB].time.from.toDate();

    const timeAObj = new Date(timeA);
    const timeBObj = new Date(timeB);
    return timeAObj - timeBObj;
  });
}
