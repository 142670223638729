import Header from "../../Components/Header";
import { eachDayOfInterval } from "date-fns";
import EventDaySwitch from "./Switch";
import Timeline from "./Timeline";
import { useDB } from "../../Context/DBContext";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function Event() {
  const { eventMetadata } = useDB();
  const [currentDate, setCurrentDate] = useState("");
  const { eventID } = useParams();

  const datesOfInterval = eachDayOfInterval({
    start: eventMetadata[0].data.date.from.toDate(),
    end: eventMetadata[0].data.date.to.toDate(),
  });

  return (
    <div className="text-custom-darkgray h-full flex flex-col ">
      <Header text="Check Availability" backRoute={`/event/${eventID}`} />
      <div className="overflow-y-auto">
        <EventDaySwitch
          datesOfInterval={datesOfInterval}
          setCurrentDate={setCurrentDate}
        />
        <Timeline currentDate={currentDate} />
      </div>
    </div>
  );
}
