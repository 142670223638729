import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowButton } from "../../Components/Button";
import DoorJames from "./../../Images/DoorJamesScheduling.svg";
import CGI from "./../../Images/CGI.png";
import { useDB } from "./../../Context/DBContext";

export default function Homepage() {
  const navigate = useNavigate();
  const { eventMetadata } = useDB();

  const openEventHandler = () => {
    navigate("info");
  };

  return (
    <div className="w-full h-full flex grow justify-center items-center">
      <div className="h-full flex flex-col justify-around items-center">
        <HomepageTitle eventMetadata={eventMetadata} />
        <div className="w-72 flex flex-col items-center">
          <HomepageLogo />
          <HomepageOpenEventButton eventMetadata={eventMetadata} action={openEventHandler} />
        </div>
        <HomepageSmallLogo />
      </div>
    </div>
  );
}

function HomepageLogo() {
  return <img className="w-52" src={DoorJames} alt="Door_James_Logo" />;
}

function HomepageTitle({ eventMetadata }) {
  return (
    <p className="max-w-lg px-2 sm:px-4 md:px-10 text-2xl sm:text-3xl font-medium mt-12 sm:mt-20 md:mt-34 text-center">
      {eventMetadata[0]?.data.title}
    </p>
  );
}

function HomepageOpenEventButton({ action, eventMetadata }) {
  return (
    <div className="mt-10 w-full">
      <ArrowButton
        type="filled"
        text={eventMetadata[0]?.data.callToAction}
        action={action}
      />
    </div>
  );
}

function HomepageSmallLogo() {
  return (
    <div className="flex flex-col gap-16 justify-center items-center">
      <img className="w-20 sm:w-28" src={CGI} alt="CGI_Logo" />{" "}
      <HomepagePrivacyPolicy />
    </div>
  );
}

function HomepagePrivacyPolicy() {
  const url = "http://doorjames.com/privacy";
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-custom-darkgray underline text-sm cursor-pointer"
    >
      Privacy Policy
    </a>
  );
}
