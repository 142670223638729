import React from "react";

export default function Textarea({
  onKeyDownEvent,
  placeholder,
  text,
  note,
  valueRef,
  required = false,
}) {
  return (
    <div className="xs:w-96">
      <div className="flex gap-2">
        <label className="flex gap-1">{text}<p className="text-sm text-custom-lightgray-2 flex justify-center items-center">{note}</p></label>
        {required && (
          <p className="text-custom-blue text-lg font-semibold">*</p>
        )}
      </div>
      <textarea
        ref={valueRef}
        required={required}
        onKeyDown={onKeyDownEvent}
        type="text"
        placeholder={placeholder}
        className="max-w-lg h-28 resize-none px-3 py-2 rounded-md w-full outline outline-1 outline-custom-lightgray-2 focus:outline-2 focus:outline-custom-blue"
      ></textarea>
    </div>
  );
}
