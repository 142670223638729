import React from "react";
import { DefaultButton } from "../Button";
import PopupContainer from "./PopupContainer";
import successIcon from "./../../Images/Success.svg";

export default function SuccessPopup({ setopen }) {
  return (
    <PopupContainer>
      <div className="bg-white z-50 mx-10 w-72 rounded-3xl p-6">
        <div className="w-full flex-col flex justify-center">
          <p className="text-xl font-semibold text-center">
            Your Booking is confirmed!
          </p>
          <div className="w-full flex justify-center mt-4">
            <img className="w-20 h-20" src={successIcon} alt="check icon" />
          </div>
          <div>
            <p className="text-center mt-6">
              Your booking information will be sent to your E-Mail.
            </p>
          </div>
          <DefaultButton text="OK" action={() => setopen(false)} />
        </div>
      </div>
    </PopupContainer>
  );
}
