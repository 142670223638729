import React, { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineEvent, MdOutlinePersonOutline } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import logoSmall from "./../../Images/LogoWhite.svg";
import logoBig from "./../../Images/DoorJamesSchedulingWhite.svg";

export default function Sidebar({ tabindex, settabindex }) {
  const [open, setopen] = useState(false);

  return (
    <div x-data="{isOpen:true}">
      <div
        className={`flex flex-col h-full bg-custom-blue ${
          open ? "w-60" : "w-24"
        }`}
      >
        <div className="flex justify-center items-center py-6">
          {/* <img
            src={open ? logoBig : logoSmall}
            alt="logo"
            className={open ? "w-full px-14" : "w-12"}
          /> */}
          {open ? (
            <img src={logoBig} alt="logo" className={"w-full px-14"} />
          ) : (
            <img src={logoSmall} alt="logo" className={"w-12"} />
          )}
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col items-center gap-6 mt-20">
            <div className="w-full pr-4">
              <div
                onClick={() => settabindex(0)}
                className={`h-14 rounded-r-lg flex items-center ${
                  open ? "pl-14" : "justify-center"
                } ${
                  tabindex === 0 ? "bg-white text-custom-black" : "text-white"
                }`}
              >
                <div className="flex gap-3 items-center">
                  <MdOutlineEvent className="w-8 h-8" />
                  {open && <span className="font-medium text-lg">Events</span>}
                </div>
              </div>
            </div>
            <div className="w-full pr-4">
              <div
                onClick={() => settabindex(1)}
                className={`h-14 rounded-r-lg flex items-center ${
                  open ? "pl-14" : "justify-center"
                } ${
                  tabindex === 1 ? "bg-white text-custom-black" : "text-white"
                }`}
              >
                <div className="flex gap-3 items-center">
                  <MdOutlinePersonOutline className="w-8 h-8" />
                  {open && <span className="font-medium text-lg">Team</span>}
                </div>
              </div>
            </div>
            <div className="w-full pr-4">
              <div
                onClick={() => settabindex(2)}
                className={`h-14 rounded-r-lg flex items-center ${
                  open ? "pl-14" : "justify-center"
                } ${
                  tabindex === 2 ? "bg-white text-custom-black" : "text-white"
                }`}
              >
                <div className="flex gap-3 items-center">
                  <FiSettings className="w-8 h-8" />
                  {open && (
                    <span className="font-medium text-lg">Settings</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center p-6 ${
              open ? "justify-end" : "justify-center"
            }`}
            onClick={() => setopen(!open)}
          >
            <IoIosArrowUp
              className={`w-8 h-8 cursor-pointer text-white ${
                open ? "-rotate-90" : "rotate-90"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
