import { useEffect, useState } from "react";
import { format, getDate, getYear } from "date-fns";
import { IoIosArrowForward } from "react-icons/io";

export default function EventDaySwitch({ datesOfInterval, setCurrentDate }) {
  const [firstSwitchDayIndex, setfirstSwitchDayIndex] = useState(0);
  const [switchDays, setswitchDays] = useState([]);
  const [selectedDayIndex, setselectedDayIndex] = useState(0);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const AMOUNT_SWITCH_DAYS = 3;

  function increaseFirstSwitchDayIndex() {
    //Switches highlighted date
    if (firstSwitchDayIndex >= datesOfInterval.length - AMOUNT_SWITCH_DAYS)
      return; //MAX Index
    setfirstSwitchDayIndex(firstSwitchDayIndex + 1);
  }

  function decreaseFirstSwitchDayIndex() {
    if (firstSwitchDayIndex === 0) return; //MIN Index
    setfirstSwitchDayIndex(firstSwitchDayIndex - 1);
  }

  function increaseSelectedDayIndex() {
    //Checks if new dates needs to be shown or if it can switch between the dates already shown
    if (selectedDayIndex === 2) {
      increaseFirstSwitchDayIndex();
    } else {
      setselectedDayIndex(selectedDayIndex + 1);
    }
  }

  function decreaseSelectedDayIndex() {
    if (selectedDayIndex === 0) {
      decreaseFirstSwitchDayIndex();
    } else {
      setselectedDayIndex(selectedDayIndex - 1);
    }
  }

  useEffect(() => {
    const switchDaysIntervall = datesOfInterval.slice(
      firstSwitchDayIndex,
      firstSwitchDayIndex + 3
    );
    setswitchDays(switchDaysIntervall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSwitchDayIndex]);

  function renderSwitchDays() {
    return switchDays.map((day, index) => {
      return (
        <EventDay
          setselectedDayIndex={() => setselectedDayIndex(index)}
          key={index}
          date={day}
          selected={index === selectedDayIndex}
          setmonth={setmonth}
          setyear={setyear}
          setCurrentDate={setCurrentDate}
        />
      );
    });
  }

  return (
    <div className="flex justify-center items-center flex-col gap-4 w-full">
      <p className="text-lg xxs:text-xl font-medium pt-6 text-custom-blue">
        Select a day to see more slots
      </p>

      <div className="w-full flex items-center flex-col gap-2">
        <p className="text-base xxs:text-lg font-medium text-custom-lightgray-3">
          {month} {year}
        </p>
        <div className="grid grid-cols-5 max-w-fit items-center">
          <button className="p-4 flex justify-center items-center">
            <IoIosArrowForward
              onClick={decreaseSelectedDayIndex}
              className="cursor-pointer rotate-180 w-6 h-6 xxs:w-7 xxs:h-7 hover:text-custom-blue"
            />
          </button>
          {renderSwitchDays()}
          <button className="p-4 flex justify-center items-center">
            <IoIosArrowForward
              onClick={increaseSelectedDayIndex}
              className="cursor-pointer w-6 h-6 xxs:w-7 xxs:h-7 hover:text-custom-blue"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function EventDay({
  selected,
  date,
  setselectedDayIndex,
  setmonth,
  setyear,
  setCurrentDate,
}) {
  const dayName = format(date, "EEEE"); //Month starts at 0
  const dayNumber = getDate(date);

  useEffect(() => {
    if (selected) {
      setCurrentDate(format(date, "yyyy-MM-dd"));
      setmonth(format(date, "LLLL"));
      setyear(getYear(date));
    }
  });

  return (
    <div
      onClick={setselectedDayIndex}
      className={`${
        selected && "bg-custom-blue text-white"
      } p-3 cursor-pointer flex justify-center items-center select-none rounded-md`}
    >
      <div className="flex flex-col justify-center items-center">
        <p className="-mt-2 text-sm xxs:text-base">{dayName}</p>
        <p className="font-medium xxs:text-xl text-xl h-6">{dayNumber}</p>
      </div>
    </div>
  );
}
