import React, { useState } from "react";
import ContentContainer from "../../../Components/Dashboard/content";
import Navbar from "../../../Components/Dashboard/navbar";
import Sidebar from "../../../Components/Dashboard/sidebar";
import Events from "./Events/Events";
import Settings from "./Settings/Settings";
import Team from "./Team/Team";

export default function Dashboard() {
  const [tabindex, settabindex] = useState(0);

  const renderTab = () => {
    switch (tabindex) {
      case 0:
        return <Events />;
      case 1:
        return <Team />;
      case 2:
        return <Settings />;
      default:
        return <></>;
    }
  };

  return (
    <div className="h-full w-full flex bg-custom-smoke-white">
      <Sidebar tabindex={tabindex} settabindex={settabindex} />
      <div className="flex flex-col w-full">
        <Navbar />
        <ContentContainer>{renderTab()}</ContentContainer>
      </div>
    </div>
  );
}
