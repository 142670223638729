import React from "react";
import { ArrowButton } from "../Button";
import { useNavigate } from "react-router-dom";
import { LanguageButton } from "../Button";

export default function Header({ text, backRoute }) {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(backRoute);
  };

  return (
    <div className="h-20 custom-shadow flex justify-center items-center px-4 xs:px-4 sm:px-6 md:px-8 shrink-0">
      <div className="max-w-7xl w-full flex justify-between items-center">
        <ArrowButton action={goBackHandler} type="back" />
        <p className="text-lg xxs:text-xl font-semibold text-custom-black">{text}</p>
        <LanguageButton action={() => ""} />
      </div>
    </div>
  );
}
