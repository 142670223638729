import React from "react";

export default function BookingPolicyCheckbox({
  settermsChecked,
  onKeyDownEvent,
}) {
  const url = "http://doorjames.com/privacy";
  return (
    <div className="flex gap-4 font-medium xs:w-96">
      <input
        onKeyDown={onKeyDownEvent}
        className="w-5 h-5 shrink-0"
        type="checkbox"
        onClick={(e) => settermsChecked(e.target.checked)}
      />
      <div className="flex">
        <p>
          I agree to the Door James&nbsp;
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-custom-blue underline cursor-pointer"
          >
            Privacy Policy *
          </a>
        </p>
      </div>
    </div>
  );
}
