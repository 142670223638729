import React, { useEffect, useState } from "react";

export default function Input({
  onKeyDownEvent,
  placeholder,
  text,
  valueRef,
  required = false,
  checkInputFnc = () => true,
  setformatAllowed = () => true,
}) {
  const [allowedValue, setallowedValue] = useState(true);

  const checkChange = (e) => {
    const value = e.target.value;
    setallowedValue(checkInputFnc(value));
  };

  useEffect(() => {
    setformatAllowed(allowedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedValue]);

  return (
    <div className="xs:w-96">
      <div className="flex gap-2">
        <label className={`${required && "text-custom-blue"}`}>{text}</label>
        {required && (
          <p className="text-custom-blue text-lg font-semibold">*</p>
        )}
      </div>
      <input
        onChange={checkChange}
        ref={valueRef}
        required={required}
        onKeyDown={onKeyDownEvent}
        type="text"
        placeholder={placeholder}
        className={`max-w-lg px-3 py-2 rounded-md w-full outline outline-1 outline-custom-lightgray-2 focus:outline-2 ${
          !allowedValue && "outline-custom-red outline-2 outline-double"
        } focus:outline-custom-blue`}
      ></input>
    </div>
  );
}
