import React from "react";
import { IoLanguage } from "react-icons/io5";

export default function LanguageButton({ action }) {
  return (
    <button
      type="button"
      onClick={action}
      className="bg-custom-lightgray-1 opacity-0 rounded-full p-2 flex justify-center items-center text-white font-medium"
    >
      <IoLanguage className="w-5 h-5 text-black" />
    </button>
  );
}
