import React from "react";
import { DefaultButton } from "./../Button";
import warningIcon from "./../../Images/Warning.svg";
import PopupContainer from "./PopupContainer";

export default function WarningPopup({ setopen }) {
  return (
    <PopupContainer>
      <div className="bg-white z-50 mx-10 w-72 rounded-3xl p-6">
        <div className="w-full flex-col flex justify-center">
          <p className="text-xl font-semibold text-center">
            No more seats available
          </p>
          <div className="w-full flex justify-center mt-4">
            <img className="w-20 h-20" src={warningIcon} alt="check icon" />
          </div>
          <DefaultButton text="OK" action={() => setopen(false)} />
        </div>
      </div>
    </PopupContainer>
  );
}
