import React from "react";

export default function DefaultButton({ action, text }) {
  return (
    <div
      onClick={action}
      className="cursor-pointer w-full bg-custom-blue hover:bg-custom-darkblue mt-5 rounded-full p-2 flex justify-center items-center gap-2 text-white font-medium"
    >
      <p>{text}</p>
    </div>
  );
}
