import { Route, Routes } from "react-router-dom";
import Homepage from "./../Pages/Homepage";
import Booking from "./../Pages/Booking";
import PageNotFound from "./../Pages/PageNotFound";
import Event from "./../Pages/Event";
import { DBProvider } from "../Context/DBContext";
import Dashboard from "./../Pages/Dashboard/Overview/index";
import Login from "./../Pages/Dashboard/Login/Login";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "../Context/AuthContext";

export default function Routing() {
  return (
    <Routes>
      <Route path="admin">
        <Route
          index
          element={
            <AuthProvider>
              <Login />
            </AuthProvider>
          }
        />
        <Route
          path="dashboard"
          element={
            <AuthProvider>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </AuthProvider>
          }
        />
      </Route>
      <Route path="event/:eventID">
        <Route index element={wrapContext(<Homepage />)} />
        <Route path="info" element={wrapContext(<Event />)} />
        <Route path="booking" element={wrapContext(<Booking />)} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

function wrapContext(element) {
  return <DBProvider>{element}</DBProvider>;
}
