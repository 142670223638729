import React from "react";

export default function PopupContainer({ children }) {
  return (
    <div className="w-full h-full absolute flex justify-center items-center z-40">
      {children}
      <div className="bg-custom-darkgray w-full h-full absolute opacity-70"></div>
    </div>
  );
}
