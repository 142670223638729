import React, { useState } from "react";
import { useContext } from "react";
import { ErrorPopup, SuccessPopup, WarningPopup } from "../Components/Popup";

const PopupContext = React.createContext();

export function usePopup() {
  return useContext(PopupContext);
}

export function PopupProvider({ children }) {
  const [open, setopen] = useState(false);
  const [type, settype] = useState("");

  const value = {
    open,
    type,
    settype,
    setopen,
    renderPopup,
  };

  const TYPES = {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning",
  };

  function renderPopup() {
    switch (type) {
      case TYPES.ERROR:
        return <ErrorPopup setopen={setopen} />;
      case TYPES.SUCCESS:
        return <SuccessPopup setopen={setopen} />;
      case TYPES.WARNING:
        return <WarningPopup setopen={setopen} />;
      default:
        return <></>;
    }
  }

  return (
    <PopupContext.Provider value={value}>{children}</PopupContext.Provider>
  );
}
