import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import app from "../Firebase/config";
import { mapDocs } from "../Helpers/mapData";
import { sortMeetingsByDay } from "./../Helpers/sortMeetings";

const DBContext = React.createContext();

export function useDB() {
  return useContext(DBContext);
}

export function DBProvider({ children }) {
  const [loading, setloading] = useState(true);
  const [eventMetadata, seteventMetadata] = useState([]);
  const [eventMeetings, seteventMeetings] = useState();
  const { eventID } = useParams();
  const navigate = useNavigate();

  const db = getFirestore(app);

  const value = {
    eventMetadata,
    eventMeetings,
    setDataDocument,
    getDataDocument,
    setDataDocumentField,
  };

  function getDataCollection(path) {
    const colRef = collection(db, ...path);
    return getDocs(colRef);
  }

  function getDataCollectionQuery(path) {
    const colRef = collection(db, ...path);
    const q = query(colRef, where("public", "==", true));
    return getDocs(q);
  }

  function getDataCollectionOnChange(path) {
    const colRef = collection(db, ...path);
    const q = query(colRef, where("public", "==", true));
    onSnapshot(q, (res) => {
      const responseList = mapDocs(res);
      const meetings = sortMeetingsByDay(responseList);
      seteventMeetings(meetings);
    });
  }

  function getDataDocument(path) {
    const docRef = doc(db, ...path);
    return getDoc(docRef);
  }

  function setDataDocument(path, newValue) {
    const docRef = doc(collection(db, ...path));
    return setDoc(docRef, newValue);
  }

  function setDataDocumentField(path, newValue) {
    const docRef = doc(db, ...path);
    return updateDoc(docRef, newValue);
  }

  function metadataHandler(path) {
    getDataCollection(path)
      .then((res) => {

        const eventList = mapDocs(res);
        if (eventList.length < 1 || eventList === undefined) {
          navigate("/");
        }
        seteventMetadata(eventList);
      })
      .then(() => setloading(false))
      .catch((error) => console.log(error));
  }

  function dataHandler(path) {
    getDataCollectionQuery(path).then((res) => {
      const responseList = mapDocs(res);
      const meetings = sortMeetingsByDay(responseList);
      seteventMeetings(meetings);
    });
  }

  /* function dataHandlerOnDataChange() {
    getDataCollectionOnChange.then((res) => {
      const responseList = mapDocs(res);
      const meetings = sortMeetingsByDay(responseList);
      seteventMeetings(meetings);
    });
  } */

  useEffect(() => {
    metadataHandler(["Events", eventID, "metadata"]);
    dataHandler(["Events", eventID, "data"]);
    getDataCollectionOnChange(["Events", eventID, "data"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  return (
    <DBContext.Provider value={value}>
      {!loading && children}
    </DBContext.Provider>
  );
}

//! Documentation
//docRef = doc(db, "path", "path") //Even amount of Paths
//colRef = collection(db, "path") //Odd amount of Paths
