import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

export default function Events() {
  const [tabIndex, settabIndex] = useState(0);

  return (
    <div className="flex flex-col gap-10">
      <div className="w-full grid grid-cols-3 justify-between">
        <div></div>
        <div className="w-full flex justify-center">
          <div className="flex w-fit rounded-full border-2 border-custom-blue justify-center items-center">
            <div
              onClick={() => settabIndex(0)}
              className={`w-32 rounded-full px-4 h-full flex justify-center items-center ${
                tabIndex === 0
                  ? "text-white bg-custom-blue -ml-0.5"
                  : "text-custom-black font-medium"
              }`}
            >
              Upcoming
            </div>
            <div
              onClick={() => settabIndex(1)}
              className={`w-32 rounded-full px-4 h-full flex justify-center items-center ${
                tabIndex === 1
                  ? "text-white bg-custom-blue -mr-0.5"
                  : "text-custom-black font-medium"
              }`}
            >
              Past
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="rounded-full text-white bg-custom-blue hover:bg-custom-darkblue px-4 py-2 flex justify-center items-center gap-2">
            <AiOutlinePlus className="text-white h-6 w-6" />
            New Event
          </button>
        </div>
      </div>
      <div className="w-full bg-red-200 h-20"></div>
      <div className="w-full bg-red-200 overflow-y">
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
        <div className="h-20">Test</div>
      </div>
    </div>
  );
}
