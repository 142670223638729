import { FiInfo } from "react-icons/fi";

export default function BookingEventCheckbox({
  eventData,
  valueRef,
  onKeyDownEvent,
}) {
  return (
    <div className="flex gap-4 font-medium xs:w-96">
      <input
        onKeyDown={onKeyDownEvent}
        ref={valueRef}
        className="w-5 h-5 shrink-0"
        type="checkbox"
      />
      <p className="text-lg">{eventData.title}</p>
      <div className="text-custom-blue h-fit relative group">
        <FiInfo className="h-full w-6" />
        <label
          className={`hidden rounded-md group-hover:block w-60 xxs:w-80 absolute top-0 right-10 bg-white custom-shadow p-4`}
        >
          {eventData.description}
        </label>
      </div>
    </div>
  );
}
