import React from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import DoorJamesSchedulingWhite from "./../../../Images/DoorJamesSchedulingWhite.svg";
import { useForm } from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";

export default function Login() {
  return (
    <div className="h-full w-full flex justify-center items-center loginbg">
      <div className="flex flex-col items-center gap-8">
        <img src={DoorJamesSchedulingWhite} alt="logo" className="w-48" />
        <div className="bg-white rounded-3xl p-10 custom-shadow w-96">
          <div className="w-full flex flex-col justify-center items-center gap-2">
            <p className="text-3xl font-semibold">Login</p>
            <p className="">Manage your Events!</p>
          </div>
          <div className="mt-8">
            <LoginInput />
          </div>
        </div>
      </div>
    </div>
  );
}

function LoginInput() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    try {
      await login(data.email, data.password);
      navigate("dashboard");
    } catch (e) {
      setError("formInput", {
        type: "custom",
        message: "Invalid Login Credentials",
      });
      throw new Error("Login credentials wrong");
    }
  };

  return (
    <form {...register("formInput")} onSubmit={handleSubmit(handleLogin)}>
      <div className="flex gap-4 flex-col">
        <div className="flex flex-col">
          <label className="font-medium">
            E-Mail
            {errors.email && (
              <span className="text-custom-blue">
                {" "}
                - This field is required
              </span>
            )}
          </label>
          <input
            {...register("email", { required: true })}
            className="border-2 rounded-full px-6 py-2 focus:border-custom-blue focus:outline-none"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-medium">
            Password
            {errors.password && (
              <span className="text-custom-blue">
                {" "}
                - This field is required
              </span>
            )}
          </label>
          <input
            type="password"
            {...register("password", { required: true, min: 8 })}
            className="border-2 rounded-full px-6 py-2 focus:border-custom-blue focus:outline-none"
          />
        </div>
        {/* <div className="flex gap-4">
        <input type="checkbox" className="w-6 h-6" />
        <label>Remember me</label>
      </div> */}
        <div>
          {errors.formInput && (
            <div className="w-full flex justify-center items-center gap-2 text-custom-red">
              <BiErrorCircle className="w-6 h-6" />
              <span>{errors.formInput.message}</span>
            </div>
          )}
        </div>
        <input
          value="Submit"
          type="submit"
          className="bg-custom-blue hover:bg-custom-darkblue rounded-full py-2 text-white w-full"
        />
      </div>
    </form>
  );
}
