import React, { createContext, useContext } from "react";
import { FiArrowRight } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";

const ArrowButtonContext = createContext();
const { Provider: ArrowButtonProvider } = ArrowButtonContext;

export default function ArrowButton({ action, text, type, disabled }) {
  const TYPES = {
    FILLED: "filled",
    BORDER: "border",
    BACK: "back",
  };

  const selectButtonType = () => {
    switch (type) {
      case TYPES.FILLED:
        return <ArrowButtonFilled />;
      case TYPES.BORDER:
        return <ArrowButtonBorder />;
      case TYPES.BACK:
        return <ArrowButtonBack />;
      default:
        return "";
    }
  };

  const values = {
    text,
    action,
    disabled,
  };

  return (
    <ArrowButtonProvider value={values}>
      {selectButtonType()}
    </ArrowButtonProvider>
  );
}

function ArrowButtonBorder() {
  const { text, action, disabled } = useContext(ArrowButtonContext);

  return (
    <button
      onClick={action}
      disabled={disabled}
      className={
        "w-full border-2 border-custom-blue rounded-full p-2 flex justify-center items-center gap-2 text-custom-blue font-medium"
      }
    >
      <p>{text}</p>
      <FiArrowRight className="w-5 h-5" />
    </button>
  );
}

function ArrowButtonFilled() {
  const { text, action, disabled } = useContext(ArrowButtonContext);
  return (
    <button
      onClick={action}
      disabled={disabled}
      className={`w-full ${
        disabled
          ? "bg-custom-lightblue"
          : "bg-custom-blue hover:bg-custom-darkblue"
      }  rounded-full p-2 flex justify-center items-center gap-2 text-white font-medium`}
    >
      <p>{text}</p>
      <FiArrowRight className="w-5 h-5" />
    </button>
  );
}

function ArrowButtonBack() {
  const { action, disabled } = useContext(ArrowButtonContext);
  return (
    <button
      disabled={disabled}
      onClick={action}
      className="bg-custom-lightgray-1 rounded-full p-2 flex justify-center items-center text-white font-medium"
    >
      <IoIosArrowForward className="w-5 h-5 rotate-180 text-black" />
    </button>
  );
}
