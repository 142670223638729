import { FiArrowRight } from "react-icons/fi";

export default function BookingSubmitButton({ termsChecked }) {
  return (
    <div className="xs:w-96">
      <button
        type="submit"
        className={`w-full ${
          !termsChecked
            ? "bg-custom-lightblue"
            : "bg-custom-blue hover:bg-custom-darkblue"
        }  rounded-full p-2 flex justify-center items-center gap-2 text-white font-medium`}
        disabled={!termsChecked}
      >
        <p>Book now</p>
        <FiArrowRight className="w-5 h-5" />
      </button>
    </div>
  );
}
