import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { sortMeetingsByTime } from "../../Helpers/sortMeetings";
import { useDB } from "./../../Context/DBContext";
import {
  format,
  /* intervalToDuration,
  addSeconds,
  formatDuration, */
} from "date-fns";
import { MdEventSeat } from "react-icons/md";
import meetingRoom from "./../../Images/MeetingRoom.svg";
import ArrowButton from "./../../Components/Button/ArrowButton";
import { AiFillLinkedin } from "react-icons/ai";
import { DateTime } from "luxon";

export default function Timeline({ currentDate }) {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const { eventMeetings } = useDB();

  const goBookHandler = (id) => {
    const queryParams = {
      meetingID: id,
    };
    navigate({
      pathname: `/event/${eventID}/booking`,
      search: `?${createSearchParams(queryParams)}`,
    });
  };

  const renderMeetings = () => {
    sortMeetingsByTime(eventMeetings.get(currentDate));

    return eventMeetings.get(currentDate)?.meetings.map((meeting) => {
      const meetingID = Object.keys(meeting)[0];
      return (
        <Meeting
          key={meetingID}
          id={meetingID}
          goBookHandler={goBookHandler}
          {...meeting[meetingID]}
        />
      );
    });
  };

  const formatDate = (type) => {
    try {
      return format(new Date(currentDate + "T00:00:00"), type);
    } catch {}
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center w-full max-w-7xl">
        <div className="flex pt-4 px-3 xs:px-4 sm:px-6 md:px-8 max-w-7xl">
          <p>Availability for&nbsp;</p>
          <p className="font-semibold">
            {formatDate("dd")}&nbsp;
            {formatDate("MMMM")},&nbsp;
            {formatDate("yyyy")}
          </p>
        </div>
        <div className="flex justify-center">
          <div className="flex xs:grid sm:grid-cols-2 md:grid-cols-3 max-w-7xl gap-6 flex-col w-full items-start pt-4 p-3 xs:p-4 sm:p-6 md:p-8">
            {renderMeetings()}
          </div>
        </div>
        <div className="flex justify-center items-center p-4">
          <TimelinePrivacyPolicy />
        </div>
      </div>
    </div>
  );
}

function Meeting(props) {
  const { max_seats, taken_seats } = props;
  const [showInfoPopup, setshowInfoPopup] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setshowInfoPopup(true);
        }}
        className="w-full flex gap-2"
      >
        <div className="w-full custom-shadow rounded-md bg-white">
          <div className="w-full h-full flex">
            <div
              className={`h-full w-3 rounded-tl-md rounded-bl-md ${
                taken_seats >= max_seats ? "bg-custom-red" : "bg-custom-green"
              }`}
            />
            <div className="w-full">
              <MeetingInformation {...props} />
            </div>
          </div>
        </div>
        {taken_seats < max_seats && (
          <div className="bg-white rounded-md custom-shadow flex justify-center items-center cursor-pointer">
            <IoIosArrowForward className="w-12 h-8 hover:text-custom-blue" />
          </div>
        )}{" "}
      </div>
      {showInfoPopup && (
        <MeetingInformationPopup
          {...props}
          setshowInfoPopup={setshowInfoPopup}
        />
      )}
    </>
  );
}

function MeetingInformation({
  title,
  author,
  max_seats,
  taken_seats,
  time,
  profileURL,
}) {
  const isoFromDate = time.from.toDate().toISOString();
  const formattedFromDate = DateTime.fromISO(isoFromDate, {
    zone: "America/New_York",
  }).toFormat("h:mm a");

  const isoToDate = time.to.toDate().toISOString();
  const formattedToDate = DateTime.fromISO(isoToDate, {
    zone: "America/New_York",
  }).toFormat("h:mm a");

  return (
    <div className="w-full h-full flex flex-col grow p-2 px-4 gap-1">
      <p className="opacity-80 text-sm xxs:text-base">
        {formattedFromDate} - {formattedToDate}
      </p>
      <p className="font-semibold text-sm xxs:text-base">{title}</p>
      <div className="flex justify-between w-full">
        <div className="flex gap-1 items-center truncate w-28">
          {/* <p className="font-medium text-sm truncate">{author}</p>
          {profileURL && (
            <a href={profileURL}>
              <BiLink className="h-4 w-4" />
            </a>
          )} */}
        </div>
        <div className="flex gap-1 items-center min-w-fit">
          <MdEventSeat className={`w-4 h-4 xxs:w-5 xxs:h-5`} />
          <p className={`block ${taken_seats >= max_seats && "xxs:hidden"}`}>
            {max_seats - taken_seats}
          </p>
          <p className="hidden xxs:block">
            {max_seats <= taken_seats
              ? "Capacity is fully booked"
              : "Seats available"}
          </p>
        </div>
      </div>
    </div>
  );
}

function MeetingInformationPopup({
  author,
  description,
  goBookHandler,
  id,
  time,
  title,
  setshowInfoPopup,
  profileURL,
  max_seats,
  taken_seats,
  room,
  location,
}) {
  const isoFromDate = time.from.toDate().toISOString();
  const formattedFromTime = DateTime.fromISO(isoFromDate, {
    zone: "America/New_York",
  }).toFormat("hh:mm a");

  const isoToDate = time.to.toDate().toISOString();
  const formattedToTime = DateTime.fromISO(isoToDate, {
    zone: "America/New_York",
  }).toFormat("hh:mm a");

  /* let duration = intervalToDuration({
    start: addSeconds(
      new Date(DateTime.fromISO(isoFromDate, { zone: "utc" })),
      0
    ),
    end: addSeconds(new Date(DateTime.fromISO(isoToDate, { zone: "utc" })), 0),
  });
 */

  const [showDetails, setshowDetails] = useState(false);

  return (
    <div className="w-full h-full absolute flex justify-center items-center z-40 top-0 left-0">
      <div className="bg-white z-50 m-3 xxs:m-6 xs:m-10 rounded-2xl xxs:rounded-3xl p-4 w-full max-w-md gap-4 flex flex-col max-h-full overflow-auto">
        <div className="w-full flex justify-center">
          <img className="w-full" src={meetingRoom} alt="check icon" />
        </div>
        <div className="flex flex-col gap-4 p-1">
          <div className="flex flex-col">
            <p className="font-medium min-w-fit text-sm xxs:text-base">
              {formattedFromTime} - {formattedToTime}
            </p>
            <p className="text-md xxs:text-lg font-semibold text-black">
              {title}
            </p>
          </div>
          <div className="font-medium leading-5 text-sm xxs:text-base">
            {description}
          </div>
          <div className="flex items-center text-sm xxs:text-base">
            <p>
              by <span className="font-medium text-black">{author}</span>
            </p>
            {profileURL && (
              <a href={profileURL}>
                <AiFillLinkedin className="ml-2 h-5 w-5 text-custom-blue " />
              </a>
            )}
          </div>
          <button
            onClick={() => setshowDetails(!showDetails)}
            className="flex items-center text-sm xxs:text-base gap-1"
          >
            <p>Show Details</p>
            <IoIosArrowForward
              className={`${
                showDetails && "rotate-90"
              } text-custom-blue w-5 h-5`}
            />
          </button>
          {showDetails && (
            <div className="pl-2 flex flex-col gap-2">
              <div className="flex items-center text-sm xxs:text-base">
                <p>
                  Room: <span className="text-black font-medium">{room}</span>
                </p>
              </div>
              <div className="flex items-center text-sm xxs:text-base gap-2">
                <p>
                  Location:{" "}
                  <span className="text-black font-medium">{location}</span>
                </p>
              </div>
              <div className="flex items-center text-sm xxs:text-base">
                <p>
                  Capacity:{" "}
                  <span className="text-black font-medium">{max_seats}</span>
                </p>
              </div>
              {/* <div className="flex items-center text-sm xxs:text-base">
                <p>
                  Duration: <span className="text-black font-medium">{""}</span>
                </p>
              </div> */}
            </div>
          )}
        </div>
        <div className="flex bg-white custom shadow w-full rounded-full items-center justify-between my-2 text-sm xxs:text-base">
          <p
            onClick={() => setshowInfoPopup(false)}
            className="cursor-pointer flex items-center justify-center w-1/2 font-medium"
          >
            Cancel
          </p>
          <div className="w-1/2">
            <ArrowButton
              disabled={taken_seats >= max_seats}
              type="filled"
              text="Book now"
              action={() => goBookHandler(id)}
            />
          </div>
        </div>
      </div>
      <div
        onClick={() => setshowInfoPopup(false)}
        className="bg-custom-darkgray w-full h-full absolute opacity-70"
      />
    </div>
  );
}

function TimelinePrivacyPolicy() {
  const url = "http://doorjames.com/privacy";
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-custom-darkgray underline text-sm cursor-pointer"
    >
      Privacy Policy
    </a>
  );
}
