import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { FiLogOut } from "react-icons/fi";

export default function Navbar() {
  const { logout } = useAuth();

  return (
    <div className="w-full bg-white custom-shadow">
      <div className="flex justify-between items-center h-full px-14 py-6">
        <div></div>
        <div className="text-2xl font-semibold text-custom-black">
          Dashboard
        </div>
        <div>
          <button onClick={logout}>
            <FiLogOut className="w-6 h-6 text-custom-blue" />
          </button>
        </div>
      </div>
    </div>
  );
}
