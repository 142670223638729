import React, { useState } from "react";
import Header from "../../Components/Header";
import Input from "./../../Components/Input";
import { useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDB } from "./../../Context/DBContext";
import { increment } from "firebase/firestore";
import { usePopup } from "../../Context/PopupContext";
import Textarea from "../../Components/Textarea";
import {
  BookingPolicyCheckbox,
  BookingEventCheckbox,
} from "../../Components/Checkbox";
import { useForm } from "react-hook-form";
import { handleEnter } from "../../Helpers/handleFormEnter";
import { BookingSubmitButton } from "../../Components/Button";

export default function Booking() {
  const [searchParams] = useSearchParams();
  const { eventID } = useParams();
  const navigate = useNavigate();
  const {
    setDataDocument,
    getDataDocument,
    setDataDocumentField,
    eventMetadata,
  } = useDB();
  const { settype, setopen } = usePopup();
  const [termsChecked, settermsChecked] = useState(false);
  const [emailFormatAllowed, setemailFormatAllowed] = useState(false);
  const { handleSubmit } = useForm();
  const eventEnabled = eventMetadata[0]?.data?.msg_event?.enabled;

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const roleRef = useRef();
  const companyRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const wishRef = useRef();
  const msgEventRef = useRef();

  const submitBooking = async () => {
    if (!termsChecked || !emailFormatAllowed) return;

    const meetingID = searchParams.get("meetingID");
    const meetingData = (
      await getDataDocument(["Events", eventID, "data", meetingID])
    ).data();

    if (!checkIfAvailable(meetingData)) return;

    const bookingObject = createBookingObject(meetingID, meetingData);

    try {
      await setDataDocument(["Events", eventID, "bookings"], bookingObject);
      await setDataDocumentField(["Events", eventID, "data", meetingID], {
        taken_seats: increment(1),
      });

      handlePopup("success");
    } catch (error) {
      handlePopup("error");
    }
  };

  function checkIfAvailable(meetingData) {
    const taken = meetingData.taken_seats;
    const max_amount = meetingData.max_seats;

    if (taken >= max_amount) {
      handlePopup("warning");
      return false;
    } else {
      return true;
    }
  }

  function handlePopup(type) {
    navigate(`/event/${eventID}/info`);
    settype(type);
    setopen(true);
  }

  function createBookingObject(meetingID, meetingData) {
    const bookingObject = {
      eventID: eventID,
      data: {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        role: roleRef.current.value,
        company: companyRef.current.value,
        country: countryRef.current.value,
        state: stateRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        wish: wishRef.current.value,
      },
      meeting: {
        meetingID: meetingID,
        author: meetingData.author,
        title: meetingData.title,
        time: meetingData.time,
        description: meetingData.description,
        location: meetingData.location,
        room: meetingData.room,
      }
    };

    if (eventEnabled) {
      bookingObject.event = {
        msgEvent: msgEventRef.current.checked,
        data: eventMetadata[0].data.msg_event,
      };
    }

    return bookingObject;
  }

  return (
    <form onSubmit={handleSubmit(submitBooking)}>
      <div className="h-screen flex flex-col">
        <Header text="Book Slot" backRoute={`/event/${eventID}/info`} />
        <div className="overflow-y-auto grow">
          <BookingTitle />
          <div className="p-5 pb-10 flex flex-col gap-6 xs:items-center xs:justify-center">
            {eventEnabled && (
              <BookingEventCheckbox
                onKeyDownEvent={handleEnter}
                valueRef={msgEventRef}
                eventData={eventMetadata[0].data.msg_event}
              />
            )}
            <Input
              valueRef={firstNameRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. John"
              text="First name"
              required
            />
            <Input
              valueRef={lastNameRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. Doe"
              text="Last Name"
              required
            />

            <Input
              valueRef={roleRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. CEO"
              text="Role"
            />
            <Input
              valueRef={companyRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. CGI"
              text="Company"
            />
            <Input
              valueRef={countryRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. USA"
              text="Country"
            />
            <Input
              valueRef={stateRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. New York"
              text="State"
            />
            <Input
              checkInputFnc={(value) => {
                const mailFormat =
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return mailFormat.test(value);
              }}
              setformatAllowed={setemailFormatAllowed}
              formatAllowed={emailFormatAllowed}
              valueRef={emailRef}
              onKeyDownEvent={handleEnter}
              placeholder="e.g. john.doe@doorjames.com"
              text="E-Mail"
              required
            />
            <Input
              valueRef={phoneRef}
              onKeyDownEvent={handleEnter}
              placeholder="+1 123 1234 5678"
              text="Phone"
            />
            <Textarea
              valueRef={wishRef}
              onKeyDownEvent={handleEnter}
              text="Comment"
            />
            <BookingPolicyCheckbox
              onKeyDownEvent={handleEnter}
              settermsChecked={settermsChecked}
            />
            <BookingStarDescription />
            <BookingSubmitButton termsChecked={termsChecked} />
          </div>
        </div>
      </div>
    </form>
  );
}

function BookingTitle() {
  return (
    <div className="px-5 mt-8 text-xl font-semibold text-custom-blue xs:flex xs:justify-center">
      Required Information
    </div>
  );
}

function BookingStarDescription() {
  return (
    <div>
      <p className="flex gap-2">
        <span className="text-custom-blue text-xl">*</span> Required fields
      </p>
    </div>
  );
}
