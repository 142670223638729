import { PopupProvider, usePopup } from "./Context/PopupContext";
import Routing from "./Routes";

function App() {
  return (
    <div className="h-full w-full relative">
      <PopupProvider>
        <Popup />
        <Routing />
      </PopupProvider>
    </div>
  );
}

function Popup() {
  const { open, renderPopup } = usePopup();
  return <>{open && renderPopup()}</>;
}

export default App;
