export function handleEnter(event) {
  if (event.key.toLowerCase() === "enter") {
    try {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    } catch (e) {
      //No more Input fields
    }
  }
}
